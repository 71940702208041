
import {
  defineComponent,
  ref,
  reactive,
  createVNode,
  nextTick,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";
import { findAllFaq, deleteFaq } from "@/API/faq";
import { UndefStr } from "../types";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
export default defineComponent({
  setup() {
    const router = useRouter();
    const spaceSize = 6;
    const question = ref<UndefStr>(undefined);
    const category = ref<UndefStr>(undefined);
    const status = ref<UndefStr>(undefined);
    const statusList = ref<any>([
      { type: "Published", id: 2 },
      { type: "Drafted", id: 1 },
    ]);
    const categoryList = ref<any>([
      { type: "Technical Support", id: 1 },
      { type: "Business Treatment", id: 2 },
    ]);
    const reset = () => {
      question.value = undefined;
      category.value = undefined;
      status.value = undefined;
    };
    //分页组件
    const pagination = reactive({
      total: 0,
      pageIndex: 0,
      pageSize: 20,
    });
    // 查询
    const search = () => {
      const params = {
        question: question.value,
        category: category.value,
        status: status.value,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      };
      findAllFaq(params).then((res: any) => {
        dataSource.value = res.content;
        pagination.total = res.totalElements;
      });
    };
    const handleSearch = () => {
      pagination.pageIndex = 0;
      search();
    };
    search();
    const edit = (faqId: string) => {
      router.push({
        name: "FAQ Edit",
        params: {
          faqId,
        },
      });
    };
    const newEdit = () => {
      router.push({
        name: "FAQ Edit",
      });
    };

    const deleteRow = (id: string) => {
      Modal.confirm({
        title: "Are you sure you want to permanently delete this FAQ? ",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Confirm",
        okType: "primary",
        cancelText: "Cancel",
        closable: true,
        onOk() {
          deleteFaq(id).then(() => {
            message.success("Successfully Deleted");
            search();
          });
        },
      });
    };
    const columns = [
      {
        title: "Category",
        dataIndex: "categoryEn",
        align: "center",
        width: 150,
        key: "1",
      },
      {
        title: "Title",
        dataIndex: "question",
        align: "center",
        width: 200,
        key: "2",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "statusEn",
        align: "center",
        width: 100,
        key: "3",
      },
      {
        title: "Publish Date",
        dataIndex: "publishDate",
        align: "center",
        width: 150,
        key: "4",
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: 200,
        key: "5",
        slots: { customRender: "action" },
      },
    ];
    const dataSource = ref<any[]>([]);
    const tableWidth = columns.reduce((totalWidth: number, col: any) => {
      return totalWidth + col.width;
    }, 0);
    const colHeight = ref(500);
    const calculateHeight = () => {
      const table = document.getElementsByClassName("managementTable")[0];
      const clinetHeight = document.body.clientHeight;
      const top = table.getBoundingClientRect().top;
      colHeight.value = clinetHeight - top - 130;
    };
    nextTick(() => {
      calculateHeight();
    });
    onMounted(() => {
      window.addEventListener("resize", calculateHeight);
    });
    //分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.pageIndex = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      search();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageIndex = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      search();
    };

    return {
      spaceSize,
      question,
      status,
      category,
      categoryList,
      statusList,
      reset,
      handleSearch,
      dataSource,
      columns,
      tableWidth,
      colHeight,
      calculateHeight,
      pagination,
      pageChange,
      sizeChange,
      edit,
      newEdit,
      deleteRow,
    };
  },
});
