import { fetchGet, fetchPost, fetchPut, fetchDelete } from "./index";

export const findAllFaq = (params: {}): Promise<any> => {
  return fetchGet("usapi/faq/findall", { params: params });
};
export const findAllDealerFaq = (params: {}): Promise<any> => {
  return fetchGet("usapi/faq/dealer_page", { params: params });
};
export const deleteFaq = (id: string): Promise<any> => {
  return fetchPut(`usapi/faq/deleteFaq?id=${id}`);
};

export const getFaqInfo = (params = {}): Promise<any> => {
  return fetchGet("usapi/faq/findfaqbyid", { params });
};
export const genFaqId = (): Promise<any> => {
  return fetchGet("usapi/faq/generate_faq_id");
};
export const downloadFile = (id: string): Promise<any> => {
  return fetchGet(`usapi/faq/downloadUrl/${id}`);
};

export const saveDraft = (params = {}): Promise<any> => {
  return fetchPost("usapi/faq/saveFaq", params);
};

// edit faq页面publish
export const publish = (id: string): Promise<any> => {
  return fetchPost(`usapi/faq/configurate/publish/${id}`);
};

export const getFaqRule = (params = {}): Promise<any> => {
  return fetchGet("/usapi/faq/configurate/page/rule", { params });
};

export const getFaqUser = (params = {}): Promise<any> => {
  return fetchGet("/usapi/faq/configurate/page/user", { params });
};

// configurate user分页
export const getConfigrateUser = (params = {}): Promise<any> => {
  return fetchPost("usapi/faq/configurate/page/user", params);
};

// configurate页面显示user and rule
export const getSavedUserAndRole = (params = {}): Promise<any> => {
  return fetchGet("usapi/faq/configurate/page/user_and_rule", { params });
};

export const deleteUser = (id: string): Promise<any> => {
  return fetchDelete(`usapi/faq/configurate/delete_user/${id}`);
};

export const deleteRule = (id: string): Promise<any> => {
  return fetchDelete(`usapi/faq/configurate/delete_rule/${id}`);
};

export const clearUserAndRule = (id: string): Promise<any> => {
  return fetchDelete(`usapi/faq/configurate/clear/${id}`);
};

export const saveUserAndRule = (params = {}): Promise<any> => {
  return fetchPost("usapi/faq/configurate/save_user_and_rule", params);
};

export const getAllDealerType = (): Promise<any> => {
  return fetchGet("/dapi/noAuth/dealer/dealerType");
};
